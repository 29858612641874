import React, {useState} from 'react'
import newsletterCheck from '../../assets/icons/newsletter-check.svg';

const NewsletterFormView = () => {
	const EMAIL_REGEX = new RegExp("[a-zA-Z0-9._'+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z ]{2,4}$");
	
	const [getSubscribed, setSubscribed] = useState(false);
	const [getRole, setRole] = useState("");
	const [getEmail, setEmail] = useState("");
	const [getRoleFieldError, setRoleFieldError] = useState(false);
	const [getEmailFieldError, setEmailFieldError] = useState(false);
	
	const validateAndSubscribe = () => {
		let validateSuccessful = true;
		if (!getRole) {
			setRoleFieldError(true);
			validateSuccessful = false;
		}
		if (!getEmail || !EMAIL_REGEX.test(getEmail)) {
			setEmailFieldError(true);
			validateSuccessful = false;
		}

		if (validateSuccessful) {
			window.require(["eventLogging"], (eventLogging) => {
				let event = new eventLogging.LoggableEvent;
				event.eventType = "epNewsletterSignupEvent";
				event.email = getEmail;
				event.role = getRole;
				eventLogging.queueEvent(event);
			});
		}

		return validateSuccessful;
	}
	
	const clearErrorsAndSetField = (event, errorSetter, fieldSetter) => {
		errorSetter(false);
		fieldSetter(event.target.value);
	}
	
	return (
	 <div id="newsletterForm" className="newsletter-form">
		 <div className="newsletter-form--contents">
			 <h2 className="newsletter-form--header">Sign up for our newsletter</h2>
			 <div className="newsletter-form--header__subtext">Get the latest on testing strategies and tips for college admissions</div>
			 <div className="newsletter-form--field">
				 <div className="newsletter-form--field__label">Your role</div>
				 <select className={"newsletter-form--field__input" + (getRole ? "" : " newsletter-form--field__select-empty") + (getRoleFieldError ? " newsletter-form--field__input-error" : "")}
							data-cname="newsletter_form_role" value={getRole} onChange={(e) => clearErrorsAndSetField(e, setRoleFieldError, setRole)}>
					 <option value="" disabled selected className="newsletter-form--field__select-placeholder">Select from dropdown</option>
					 <option value="PARENT" className="newsletter-form--field__select-option">Parent</option>
					 <option value="STUDENT" className="newsletter-form--field__select-option">Student</option>
					 <option value="COUNSELOR_OR_TEACHER" className="newsletter-form--field__select-option">Counselor or teacher</option>
				 </select>
			 </div>
			 <div className="newsletter-form--field">
				 <div className="newsletter-form--field__label">Email</div>
				 <input className={"newsletter-form--field__input" + (getEmailFieldError ? " newsletter-form--field__input-error" : "")} placeholder="example@gmail.com" data-cname="newsletter_form_email" value={getEmail}
						  onChange={(e) => clearErrorsAndSetField(e, setEmailFieldError, setEmail)}/>
			 </div>
			 <button className="newsletter-form--subscribe" data-cname="newsletter_form_subscribe" onClick={() => validateAndSubscribe() && setSubscribed(true)}>
				 {getSubscribed ? <span className="newsletter-form--subscribed-text"><img alt="" src={newsletterCheck}/>You're subscribed!</span> : <span>Subscribe</span>}
			 </button>
		 </div>
	 </div>
	)
}

export default NewsletterFormView;