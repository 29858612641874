import Seo from "../components/Seo";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Flex} from "@chakra-ui/react";
import RichContent from "../components/Collections/RichContent";
import Slider from '../components/Interactive/slider'
import Images from "../components/Interactive/image";
import NewsletterFormView from "../sections/Forms/form-newsletter";

const Breadcrumbs = ({data}) => {
	return <div className="resource-index-breadcrumbs">
		<span className="resource-index-breadcrumb mobile-inline-block resource-index-breadcrumb-separator">‹</span>
		<span className="resource-index-breadcrumb"><a href="/">Home</a></span>
		<span className="resource-index-breadcrumb desktop-inline-block resource-index-breadcrumb-separator">›</span>
		<span className="resource-index-breadcrumb desktop-inline-block">Resources</span>
	</div>;
}

const Title = ({data}) => {
	return <div className="resource-index-title">{data?.sanityResourceIndexPage?.title}</div>;
}

const Summary = ({data}) => {
	let summary = {_rawContent : data?.sanityResourceIndexPage?._rawSummary };
	return <div className="resource-index-summary"> <RichContent data={summary}/></div>;
}

/*const categories = [
	{title: "K-12 Resources", value: "k12Resources", content: "Placeholder: This collection of articles is focused on K-12 education, which refers to the educational period that covers the early stages of childhood development through high school. The articles in this collection cover a range of topics related to K-12 education, including curriculum development, teaching methodologies, student engagement strategies, technology integration, and more."},
	{title: "College Readiness Guides", value: "collegeReadinessGuides", content: "Placeholder: This collection of articles is focused on K-12 education, which refers to the educational period that covers the early stages of childhood development through high school. The articles in this collection cover a range of topics related to K-12 education, including curriculum development, teaching methodologies, student engagement strategies, technology integration, and more."},
	{title: "College Student Resources", value: "collegeStudentResources", content: "Placeholder: This collection of articles is focused on K-12 education, which refers to the educational period that covers the early stages of childhood development through high school. The articles in this collection cover a range of topics related to K-12 education, including curriculum development, teaching methodologies, student engagement strategies, technology integration, and more."},
	{title: "Parent Guides", value: "parentGuides", content: "Placeholder: This collection of articles is focused on K-12 education, which refers to the educational period that covers the early stages of childhood development through high school. The articles in this collection cover a range of topics related to K-12 education, including curriculum development, teaching methodologies, student engagement strategies, technology integration, and more."},
	{title: "ACT & SAT Resources", value: "actSatResources", content: "Placeholder: This collection of articles is focused on K-12 education, which refers to the educational period that covers the early stages of childhood development through high school. The articles in this collection cover a range of topics related to K-12 education, including curriculum development, teaching methodologies, student engagement strategies, technology integration, and more."},
];*/

/*const TableOfContents = ({data, allPromoPages}) => {
	if (!allPromoPages) {
		return null;
	}
	
	return <div className="resource-index-toc">
		<div className="resource-index-toc__title">On this page</div>
		<ul className="resource-index-toc__links">
		{
			categories.map(category => {
				let promoPagesInCategory = allPromoPages.filter(promoPage => promoPage.header?.category === category.value);
				if (promoPagesInCategory.length === 0) {
					return null;
				}
				
				return <li key={category.value} className="resource-index-toc__link"><a href={"#" + category.value}>{category.title}</a></li>
			})
		}
		</ul>
	</div>;
}*/

const PromoPageList = ({data, allPromoPages}) => {
	if (!allPromoPages || allPromoPages.length === 0) {
		return null;
	}
	
	const renderPromoPage = (promoPage) => {
		return <div key={promoPage.id} className="resource-index-promo-page-slide">
			<Images image={promoPage.header?.promoImage?.asset?.gatsbyImageData} alt="" width='100%' height="75px" objectFit="cover" />
			<div className="resource-index-promo-page-slide__link">
				<a href={"/resources/" + promoPage.header?.slug?.current + (promoPage.header?.slug?.current?.endsWith("/") ? "" : "/")}>{promoPage.header?.title}</a>
			</div>
		</div>;
	}
	
	return <div className="resource-index-promo-page-list">
		<div className="resource-index-promo-page-category">
			{ allPromoPages.length < 4 &&
			 <div className="resource-index-promo-page-wrapper">
				 {
					 allPromoPages.map((promoPage, index) => renderPromoPage(promoPage))
				 }
			 </div>
			}
			{ allPromoPages.length > 3 &&
			 <Slider
			  navigation
			  navigationVisibleMinWidth="1024px"
			  slidesPerViewBase={1}
			  slidesPerViewSm={2}
			  slidesPerViewMd={3}
			  slidesPerViewLg={4}
			  locatorString="promo_page_slider"
			 >
				 {
					 allPromoPages.map((promoPage, index) => renderPromoPage(promoPage))
				 }
			 </Slider>
			}
		</div>
	</div>;
}

/*const PromoPageListWithCategories = ({data, allPromoPages}) => {
	if (!allPromoPages) {
		return null;
	}

	return <div className="resource-index-promo-page-list">
		{
			categories.map(category => {
				let promoPagesInCategory = allPromoPages.filter(promoPage => promoPage.header?.category === category.value);
				if (promoPagesInCategory.length === 0) {
					return null;
				}
				
				return <div className="resource-index-promo-page-category" id={category.value}>
					<h2>{category.title}</h2>
					<p>{category.content}</p>
					
					<Slider
					 navigation
					 navigationVisibleMinWidth="1024px"
					 slidesPerViewBase={1}
					 slidesPerViewSm={2}
					 slidesPerViewMd={3}
					 slidesPerViewLg={4}
					 locatorString="promo_page_slider">
						{
							promoPagesInCategory.map((promoPage, index) =>
							 <div key={promoPage.id} className="resource-index-promo-page-slide">
								 <Images image={promoPage.header?.promoImage?.asset?.gatsbyImageData} alt="" width='100%' height="75px" objectFit="cover" />
								 <div className="resource-index-promo-page-slide__link">
								 	<a href={"/resources/" + promoPage.header?.slug?.current + (promoPage.header?.slug?.current?.endsWith("/") ? "" : "/")}>{promoPage.header?.title}</a>
								 </div>
							 </div>
							)
						}
					</Slider>
				</div>;
			})
		}
	</div>;
}*/

const ResourceIndexPage = ({location, data, pageContext}) => {
	const {header, footer, allPromoPages} = pageContext;
	const seo = {
		title: data?.sanityResourceIndexPage?.metaTitle,
		metaDescription: data?.sanityResourceIndexPage?.metaDescription
	};
	
	return (
	 <Layout header={header} footer={footer}>
		 <Seo data={seo} location={location}/>
		 <Flex flexDirection='column' overflowX='hidden' className="resource-index-page">
			 <Breadcrumbs data={data} />
			 <Title data={data} />
			 <Summary data={data} />
			 {/*<TableOfContents data={data} allPromoPages={allPromoPages.nodes} />*/}
			 <PromoPageList data={data} allPromoPages={allPromoPages.nodes} />
		 </Flex>
		 <NewsletterFormView />
	 </Layout>
	);
}

export default ResourceIndexPage;

export const query = graphql`
  query ResourceIndexPageQuery{
    sanityResourceIndexPage{
    	_createdAt
    	_updatedAt
		metaTitle
		metaDescription
		title
		_rawSummary(resolveReferences: {maxDepth: 50})
    }
  }
`
